import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealBeGenuine', 'textRevealSmile', 'textRevealLaugh'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const Img = makeShortcode("Img");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "remember-"
    }}>{`Remember …`}</h1>
    <p>{`(click text below to reveal)`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Grid container spacing={3} mdxType="Grid">
      <Grid xs={12} item mdxType="Grid">
        <TextReveal id="textRevealBeGenuine" header="Be Genuine" mdxType="TextReveal">
          <p>Be genuine when you show your affection and encouragement.</p>
        </TextReveal>
      </Grid>
      <Grid xs={12} item mdxType="Grid">
        <TextReveal id="textRevealSmile" header="Smile" mdxType="TextReveal">
          <p>Smile!</p>
        </TextReveal>
      </Grid>
      <Grid xs={12} item mdxType="Grid">
        <TextReveal id="textRevealLaugh" header="Laugh" mdxType="TextReveal">
          <p>Find a way to laugh with them, not at them!</p>
        </TextReveal>
      </Grid>
    </Grid>
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Grid container spacing={3} mdxType="Grid">
      <Grid xs={12} item mdxType="Grid">
        <Img src="/images/m1/5.svg" alt="Laugh example" mdxType="Img" />
      </Grid>
    </Grid>
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      